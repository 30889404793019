<template>
  <b-form ref="formCreateClass" @submit.prevent="submitCreate">
    <div class="form-row">
      <b-col md="8">
        <b-card header="Informações básicas" class="mb-1">
          <b-card-text>
            <b-row class="form-row">
              <b-col md="6">
                <b-form-group>
                  <label for="">                    
                    <i class="text-danger bi bi-record-circle"></i>
                    Título
                  </label>
                  <b-form-input
                    required
                    v-model="item.title"
                    placeholder="Título"
                    :class="{ 'is-invalid': $v.item.title.$error }"
                  />
                </b-form-group>
              </b-col>

              <b-col md="6">
                <label for="" style="display: flow-root">
                  <span class="float-left">Subtítulo</span>
                </label>
                <b-form-group>
                  <b-form-input
                    v-model="item.subtitle"
                    placeholder="Subtítulo"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
        <b-card header="Descrição" class="mb-1">
          <b-card-text>
            <b-form-group>
              <quill-editor
                v-model="item.description"
                :options="snowOption"
                :auto-height="true"
              >
              </quill-editor>
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col md="4">
        <b-card header="Professor(a)" class="mb-1">
          <b-card-text>
            <b-form-group class="mb-0">
              <v-select
                ref="selectTeacher"
                label="title"
                item-text="title"
                item-value="code"
                v-model="item.teacher"
                placeholder="Digite o nome do professor"
                :class="{ 'is-invalid': $v.item.teacher.$error }"
                :options="optionsTeachers"
                @search="fetchTeachersOptions"
              >
                <span slot="no-options" @click="$refs.selectTeacher.open = false">
                  Nenhum registro encontrado
                </span>
              </v-select>
            </b-form-group>
          </b-card-text>
        </b-card>

        <b-card header="Vídeo e Streaming" class="mb-1">
          <b-card-text>
            <div class="form-row">
              <div v-if="item.streaming.code === 2" class="col-12 pb-2">
                <b-embed
                  type="iframe"
                  aspect="16by9"
                  :src="`https://www.youtube.com/embed/${item.code}?rel=0`"
                  allowfullscreen
                />
              </div>
              <b-col md="12">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Streaming
                </label>
                <b-form-group>
                  <v-select
                    label="title"
                    placeholder="Selecione o streaming"
                    required
                    :searchable="false"
                    item-text="title"
                    item-value="code"
                    v-model="item.streaming"
                    :options="optionsStreamings"
                    :class="{ 'is-invalid': $v.item.streaming.$error }"
                  />
                </b-form-group>
              </b-col>
              <b-col md="7">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Código do vídeo
                </label>
                <b-form-group class="mb-0 mb-m-1">
                  <b-form-input
                    v-model="item.code"
                    :class="{ 'is-invalid': $v.item.code.$error }"
                  />
                </b-form-group>
              </b-col>
              <b-col md="5">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Tempo
                </label>
                <b-form-group class="mb-0">
                  <b-form-input
                    required
                    placeholder="HH:MM:SS"
                    v-mask="['##:##:##']"
                    type="tel"
                    v-model="item.time"
                    :class="{ 'is-invalid': $v.item.time.$error }"
                  />
                </b-form-group>
              </b-col>
            </div>
          </b-card-text>
        </b-card>

        <!-- <b-card class="mb-1" header="API Questões">
          <b-card-text>
            <div class="form-group">
              <label for="">
                Disciplina
              </label>
              <v-select
                label="title"
                item-text="title"
                item-value="code"
                :searchable="false"
                v-model="item.api_question_discipline"
                placeholder="Digite o nome do professor"
                :options="optionsDisciplines"
                @input="disciplineSelected"
              />
            </div>

            <div class="form-group mb-0">
              <label for="">
                Assuntos
              </label>
              <v-select
                multiple
                label="topic"
                item-text="topic"
                item-value="code"
                v-model="item.api_question_subjects"
                placeholder="Digite o nome do professor"
                :options="optionsSubjects"
                class="v-select-blocks"
              >
                <template #option="{ topic, count }">
                  ({{ count }}) - {{ topic }}               
                </template>

                <template #selected-option="{ count, topic }">
                  ({{ count }}) - {{ topic }}
                </template>
              </v-select>
            </div>
          </b-card-text>
        </b-card> -->

        <b-card header="Material" class="mb-1">
          <b-card-text>
            <div class="form-row">
              <b-col md="12">
                <div class="form-group mb-0">
                  <v-select
                    multiple
                    ref="selectMaterial"
                    label="title"
                    item-text="title"
                    item-value="code"
                    v-model="item.materials"
                    placeholder="Digite o título do material"
                    :options="materialsOptions"
                    @search="fetchMaterialsOptions"
                  >
                    <span slot="no-options" @click="$refs.selectMaterial.open = false">
                      Nenhum registro encontrado
                    </span>
                  </v-select>
                </div>
              </b-col>
            </div>
          </b-card-text>
        </b-card>

        <b-card header="Tag(s)" class="mb-1">
          <b-card-text>
            <b-form-group>
              <label for="">                
                  <i class="text-danger bi bi-record-circle"></i>
                Tag Tema
              </label>
              <v-select
                ref="selectTema"
                multiple
                label="title"
                item-text="title"
                item-value="code"
                v-model="item.tags.subject"
                placeholder="Digite o título da tag"
                :options="optionsTagTema"
                @search="fetchTagsCategoryTema"
                :class="{ 'is-invalid': $v.item.tags.subject.$error }"
              >
                <span slot="no-options" @click="$refs.selectTema.open = false">
                  Nenhum registro encontrado
                </span>
              </v-select>
            </b-form-group>
            
            <b-form-group>
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Tag Concurso
              </label>
              <v-select
                ref="selectContest"
                multiple
                label="title"
                item-text="title"
                item-value="code"
                v-model="item.tags.contest"
                placeholder="Digite o título da tag"
                :options="optionsTagConcurso"
                @search="fetchTagsCategoryConcurso"
                :class="{ 'is-invalid': $v.item.tags.contest.$error }"
              >
                <span slot="no-options" @click="$refs.selectContest.open = false">
                  Nenhum registro encontrado
                </span>
              </v-select>
            </b-form-group>

            <b-form-group class="mb-0">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Tag Linha do tempo
              </label>
              <v-select
                ref="selectTimeline"
                multiple
                label="title"
                item-text="title"
                item-value="code"
                v-model="item.tags.timeline"
                placeholder="Digite o título da tag"
                :options="optionsTagLinhaDoTempo"
                @search="fetchTagsCategoryLinhaDoTempo"
                :class="{ 'is-invalid': $v.item.tags.timeline.$error }"
              >
                <span slot="no-options" @click="$refs.selectTimeline.open = false">
                  Nenhum registro encontrado
                </span>
              </v-select>
            </b-form-group>
          </b-card-text>
        </b-card>

        <ButtonsActionsFooter
          routerBack="lessons-list"
          variant="success"
          :submited="submited"
          text="Cadastrar"
          subtext="Aguarde..."
        />
      </b-col>
    </div>
  </b-form>
</template>

<script>
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BCardText,
  BCard,
  VBTooltip,
  VBToggle,
  BAvatar,
  BEmbed,
  BCardFooter,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapState } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import ButtonsActionsFooter from '@/views/components/button/ButtonsActionsFooter';

// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";
import axios from "axios";

export default {
  components: {
    BCard,
    BEmbed,
    BCardText,
    BFormInput,
    BFormGroup,
    vSelect,
    BForm,
    BRow,
    BCol,
    quillEditor,
    ButtonsActionsFooter,
    BAvatar,
    BCardFooter,
  },
  data() {
    return {
      submited: false,
      filteredOptions: [],
      wasUploads: 0,
      snowOption: {
        theme: 'snow',
        placeholder: '',
        height: '300',
      },
      item: {
        title: '',
        code: '',
        time: '',
        subtitle: '',
        description: '',
        teacher: '',
        streaming: '',
        materials: [],
        api_question_discipline: "",
        api_question_subjects: "",
        tags: {
          subject: [],
          contest: [],
          timeline: [],
        },
      },
      optionsStreamings: [],
      optionsTeachers: [],
      materialsOptions: [],
      optionsTagTema: [],
      optionsTagConcurso: [],
      optionsTagLinhaDoTempo: [],
      optionsDisciplines: [],
      optionsSubjects: [],
    }
  },
  validations: {
    item: {
      title: {
        required,
        minLength: minLength(5),
      },
      teacher: {
        required,
      },
      streaming: {
        required,
      },
      code: {
        required,
      },
      time: {
        required,
        minLength: minLength(8),
      },
      tags: {
        subject: {
          required,
        },
        contest: {
          required,
        },
        timeline: {
          required,
        },
      }
    },
  },
  computed: {
    ...mapState('Lesson', ['lesson']),
    ...mapState('Media', ['searchMaterials']),
    ...mapState('Tag', ['searchTagsTema', 'searchTagsConcurso', 'searchTagsLinhadoTempo']),
  },
  methods: {
    disciplineSelected (val) {
      this.optionsSubjects = [];
      axios.get(`https://api.maisquestoes.com.br/contest-new/distinct-topics?subject=${val}`).then((res) => {
        this.optionsSubjects = res.data;
        this.item.api_question_subjects = [];
      })
    },
    removeOnPaste(event) {
      event.preventDefault();
      this.item.code = this.item.code.trim();
    },
    replaceURLYouTube() {
      if(this.item.streaming.code === 2) {
        this.item.code = this.item.code.replace('https://www.youtube.com/watch?v=', '');
      }
    },
    submitCreate() {
      this.$v.$touch();
      this.submited = true;

      if (!this.$v.$error) {        
        this.$store.dispatch('Lesson/create', this.item)
        .then(() => {
          this.$router.push({ name: 'lessons-list' });
          this.notifyDefault('success');
        })
        .catch(() => {
          this.submited = false;
          this.notifyDefault('error');
        })
        .finally(() => {
          this.submited = false;
        });
      } else {
        this.submited = false;
        this.notifyDefault("error", 'Verifique os campos obrigatórios');
      }
    },
    async fetchTeachersOptions (term) {
      if (term.length > 2) {
        this.optionsTeachers = await this.$store.dispatch('Teacher/search', term)
      }
    },
    async fetchMaterialsOptions (term) {
      if (term.length > 2) {
        this.$store.dispatch('Media/searchType', {term: term, type: 1}).then(() => {
          this.materialsOptions = this.searchMaterials;
        });
      }
    },
    async fetchTagsCategoryTema (term) {
      if (term.length > 2) {
        this.optionsTagTema = await this.$store.dispatch('Tag/searchTags', { category_id: 1, term: term })
      }
    },
    async fetchTagsCategoryConcurso (term) {
      if (term.length > 2) {
        this.optionsTagConcurso = await this.$store.dispatch('Tag/searchTags', { category_id: 2, term: term })
      }
    },
    async fetchTagsCategoryLinhaDoTempo (term) {
      if (term.length > 2) {
        this.optionsTagLinhaDoTempo = await this.$store.dispatch('Tag/searchTags', { category_id: 3, term: term })
      }
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
  },
  async mounted() {
    this.optionsStreamings = await this.$store.dispatch("Streaming/forSelect");

    axios.get('https://api.maisquestoes.com.br/contest-new/distinct-subjects').then((res) => {
      this.optionsDisciplines = res.data;
    })
  }
};
</script>

<style >
.beforeUpload .icon {
  width: 50px !important;
}
</style>
